/* eslint-disable no-empty-pattern */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

export const readableBytes = (bytes) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  return `${(bytes / 1024 ** i).toFixed(2) * 1} ${sizes[i]}`
}

export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'))
  const context = canvas.getContext('2d')
  context.font = font
  const metrics = context.measureText(text)
  return metrics.width
}

export const getFileType = (extension) => {
  const imageTypes = ['jpg', 'png', 'jpeg']
  const videoTypes = ['mp4', 'mov', 'avi']
  const audioTypes = ['mp3']
  const type = extension.toLowerCase()

  if (imageTypes.includes(type)) return 'image'

  if (videoTypes.includes(type)) return 'video'

  if (audioTypes.includes(type)) return 'audio'

  return false
}

export const renderFileSrc = (type, url = '') => {
  switch (type) {
    case 'folder':
      return '/static/images/folder.svg'
    case 'audio':
      return '/static/images/audio.svg'
    case 'video':
      return '/static/images/video-thumbnail.jpg'
    default:
      return url
  }
}

export const getFileNameAndType = (name) => {
  const fileParts = name.split('.')
  const fileName = fileParts[0]
  const fileType = fileParts[1]
  return { fileName, fileType }
}

export const addArticle = (word) =>
  'AEIOUaeiou'.indexOf(word.charAt(0)) === -1 ? `a ${word}` : `an ${word}`

export const convertToTime = (num) => {
  const minutes = Math.floor(num / 60)
  const seconds = num - minutes * 60
  return `${minutes}:${`0${seconds}`.slice(-2)}`
}

export const testFilters = (filter, name) => {
  switch (filter) {
    case 'images':
      return /\.(gif|jpe?g|tiff|png|webp|bmp)$/i.test(name)
    case 'videos':
      return /\.(avi|AVI|wmv|WMV|flv|FLV|mpg|MPG|mp4|MP4|mov)$/i.test(name)
    case 'audio':
      return /\.(?:wav|mp3)$/i.test(name)
    default:
      return false
  }
}

export const formatBytes = (bytes) => {
  const marker = 1024 // Change to 1000 if required
  const decimal = 2 // Change as required
  const kiloBytes = marker // One Kilobyte is 1024 bytes
  const megaBytes = marker * marker // One MB is 1024 KB
  const gigaBytes = marker * marker * marker // One GB is 1024 MB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return `${bytes} Bytes`
  // return KB if less than a MB
  if (bytes < megaBytes) return `${(bytes / kiloBytes).toFixed(decimal)} KB`
  // return MB if less than a GB
  if (bytes < gigaBytes) return `${(bytes / megaBytes).toFixed(decimal)} MB`
  // return GB if less than a TB
  return `${(bytes / gigaBytes).toFixed(decimal)} GB`
}

/*
HELPER FUNCTION TO GET TOTAL DURATION OF AUDIO FILE
*/
export const getDuration = (src, type) =>
  ['video', 'audio'].includes(type) &&
  new Promise((resolve) => {
    const media =
      type === 'audio' ? new Audio() : document.createElement('video')
    media.addEventListener('loadedmetadata', () => {
      resolve(Math.floor(media.duration))
    })
    media.src = src
  })

// eslint-disable-next-line consistent-return
export const extractScrollableContent = (data, paginationContainer) => {
  if (data) {
    if (data[paginationContainer]) return data[paginationContainer]

    const keys = Object.keys(data)
    let keyObject = null
    keys.forEach((key) => {
      if (typeof data[key] === 'object') {
        keyObject = key
      }
    })
    return extractScrollableContent(data[keyObject], paginationContainer)
  }
}

export const isEmptyObject = (obj) => {
  for (const {} in obj) return false
  return true
}

/**
 * helper method: to check wether stage selected type is the same as asset type
 */
export const validateAssetType = (stage, type, templateType) => {
  if (type === 'audio' || templateType === 'image') return true

  if (templateType === 'video') {
    if (stage === null) return false
    if (type.includes(stage.type.toLowerCase())) return true
  }
}

/**
 * helper to render ToolTip message based on selected stage or type
 */
export const renderToolTipMessage = (updating, type) =>
  type === 'audio'
    ? 'Replace audio sound track'
    : !updating
    ? 'Add as new asset'
    : 'Replace with this asset'

/**
 * helper to get the direction of the arrow of an order by component
 */

export const getDirection = (directionsOfCriteria, orderBy) => {
  const indexOfOrderBy = directionsOfCriteria.indexOf(orderBy)
  return indexOfOrderBy !== -1 && (indexOfOrderBy === 0 ? 'UP' : 'DOWN')
}

/**
 * helper to get aspect ratio between two numbers
 */
export const calculateRatio = (num1, num2) => {
  let first = num1
  let second = num2
  for (let num = num2; num > 1; num -= 1) {
    if (first % num === 0 && second % num === 0) {
      first /= num
      second /= num
    }
  }
  return [first, second]
}

export const omitTypename = (obj) => {
  Object.keys(obj).forEach(
    (key) =>
      // eslint-disable-next-line no-param-reassign
      (key === '__typename' && delete obj[key]) ||
      (obj[key] && typeof obj[key] === 'object' && omitTypename(obj[key]))
  )
  return obj
}

export const slugify = (str) => str.replace(/\s+/g, '-').toLowerCase()

export const addImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

export const dataURItoBlob = (dataURI) => {
  const binary = atob(dataURI.split(',')[1])
  const array = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

export const addMissingIdsToAssets = (scenes, assets) =>
  assets.map((asset) => {
    const { historyId } = asset

    if (asset.id && asset.id !== -1) return asset

    let id = ''

    scenes.some((scene) => {
      const foundAsset = scene.assets.find(
        (sceneAsset) => sceneAsset.historyId === historyId
      )

      if (foundAsset) {
        id = foundAsset.id
        return true
      }
      return false
    })

    return { ...asset, id }
  })

const historyMemoStore = {}
export const addMissingIdtoScene = (scenes, scene) => {
  if (historyMemoStore[scene.historyId])
    return { ...historyMemoStore[scene.historyId], ...scene }

  historyMemoStore[scene.historyId] = scene.id
    ? scene
    : {
        id: scenes.find((s) => s.historyId === scene.historyId).id
      }

  return historyMemoStore[scene.historyId]
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const poll = async (repsInput, fnCondition, ms) => {
  let reps = 0

  while (reps <= repsInput && fnCondition()) {
    // eslint-disable-next-line no-await-in-loop
    await sleep(ms)
    reps += 1
  }
  return null
}
